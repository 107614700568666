import { ArrayElement, Error, LoginContent } from "@onzeit/shared";
import { Link, useFetcher } from "@remix-run/react";
import { PropsWithoutRef } from "react";
import { Logo } from "..";

import type { GraphQLError } from "graphql";

export const Login = ({
  content,
  locale,
  errors,
}: PropsWithoutRef<{
  content: ArrayElement<typeof LoginContent>;
  locale: string;
  errors?: Error[] | undefined;
}>) => {
  const fetcher = useFetcher<{
    success: boolean;
    errors: GraphQLError[];
  }>();

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="flex justify-center">
        <Logo />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
          Einloggen
        </h2> */}
        {/* <p className="mt-2 text-sm text-center text-gray-600">
          Or{" "}
          <a
            href="#"
            className="font-medium text-neutral-600 hover:text-neutral-500"
          >
            start your 14-day free trial
          </a>
        </p> */}
      </div>

      <div className="mx-auto mt-8 sm:max-w-md">
        <div className="sm:text-balance bg-card px-8 py-8 text-center shadow sm:rounded-lg sm:px-10">
          <fetcher.Form className="space-y-6" method="post">
            <div>
              <p className="text-primary text-2xl">{content.data.Heading}</p>
              <p className="text-md text-secondary my-4 max-w-md">
                {content.data.Subheading}
              </p>
              <label
                htmlFor="email"
                className="text-secondary block text-sm font-medium"
              >
                {content.data.EmailLabel}
              </label>
              <div className="mt-1">
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                      <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                    </svg>
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    className="text-page-background block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={content.data.EmailPlaceholder}
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                disabled={fetcher.state === "submitting"}
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {fetcher.state === "submitting" && (
                  <div role="status" className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="mr-2 mt-[2px] h-4 w-4 animate-spin fill-indigo-100 text-indigo-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )}

                {content.data.LoginLinkText}
              </button>
            </div>

            {!fetcher.data?.success && (
              <div className="text-sm text-red-500">
                {fetcher.data?.errors?.map((error: any) => (
                  <p key={error.message}>{error.message}</p>
                ))}
              </div>
            )}

            {errors && (
              <div className="text-sm text-red-500">
                {errors?.map((error) => (
                  <p key={error.message}>{error.message}</p>
                ))}
              </div>
            )}
          </fetcher.Form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">
                  {content.data.Or}
                </span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-3">
              <div>
                <Link
                  to=""
                  className="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                >
                  <div className="">
                    <svg
                      fill="#1C2033"
                      width="20"
                      height="20"
                      viewBox="0 0 64 64"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M61.5016 29.2001H32.8016V37.7001H53.4016C52.3016 49.5001 42.7016 54.6001 33.4016 54.6001C21.6016 54.6001 11.1016 45.4001 11.1016 32.1001C11.1016 19.3001 21.1016 9.60011 33.4016 9.60011C42.8016 9.60011 48.5016 15.7001 48.5016 15.7001L54.3016 9.60011C54.3016 9.60011 46.5016 1.10011 33.0016 1.10011C15.2016 1.00011 1.60156 15.9001 1.60156 32.0001C1.60156 47.6001 14.4016 63.0001 33.3016 63.0001C50.0016 63.0001 62.0016 51.7001 62.0016 34.8001C62.1016 31.3001 61.5016 29.2001 61.5016 29.2001Z" />
                    </svg>
                  </div>
                  <span className="ml-2 text-lg ">
                    {content.data.LoginWithGoogle}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
